import i18next from 'i18next';
import $ from 'jquery';
import en from './en.js';
import fr from './fr.js';

i18next.init({
    lng: $('html').attr('lang') || 'en',
    resources: {
        'en': {
            translation: en,
        },
        'fr': {
            translation: fr,
        }
    }
});

export default i18next;