'use strict';

import font from "opentype.js";

function nodeBufferToArrayBuffer(buffer) {
    const ab = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
    }

    return ab;
}

class FontUtils {

    parsedFont = null;

    /**
     * @param {String} font Path to font file
     * @param {Function|null} loader
     */
    constructor(font = "./component/fonts/Marianne-Medium.otf", loader = null) {
        const binaryContent = loader(font, null, null);

        this.font = nodeBufferToArrayBuffer(binaryContent);
        this.parsedFont = null;
    }

    getTextWidth(text, fontSize, delimiter = "\n") {
        let maxWidth = 0;
        text.split(delimiter).forEach(text => {
            const stringWidth = this.#getFont().getAdvanceWidth(text, fontSize);
            maxWidth = maxWidth >= stringWidth ? maxWidth : stringWidth;
        });

        return maxWidth
    }

    getTextHeight(text, fontSize, delimiter = "\n") {
        let totalHeight = 0;
        text.split(delimiter).forEach(text => {
            totalHeight += fontSize;
        });

        return totalHeight;
    }

    function /**
     * @param {string} text
     * @param {int} fontSize
     */
    getTextWidthAndHeight(text, fontSize) {
        const stringWidth = this.#getFont().getAdvanceWidth(text, fontSize);

        return {
            width: stringWidth,
            height: fontSize,
        };
    }

    #getFont() {
        if (this.parsedFont === null) {
            this.parsedFont = font.parse(this.font, null);
        }

        return this.parsedFont;
    }

    /**
     * @param {Number} fontSize
     *
     * @return {Number}
     */
    getCapHeight(fontSize) {
        const font = this.#getFont();

        const idx = font.tables.os2.sCapHeight / font.tables.head.unitsPerEm;

        return fontSize * idx;
    }
}

export default FontUtils;


