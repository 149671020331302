'use strict';

export default rawFont => {
    let fontBin;
    if (typeof rawFont === 'string' || rawFont instanceof String) {
        fontBin = Uint8Array.from(atob(rawFont.replace('data:font/otf;base64,', '')), c => c.charCodeAt(0));
    }

    return fontBin;
}
