(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("jquery"), require("portal/core/eventifier"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "jquery", "portal/core/eventifier"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("jquery"), require("portal/core/eventifier")) : factory(root["lodash"], root["jquery"], root["portal/core/eventifier"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__41__) {
return 