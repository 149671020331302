'use strict';

import convertBuffer from "#libs/encodeFontBuffer";

import icoCheck from "./img/ico-check.svg";
import icoCross from "./img/ico-cross.svg";
import icoDoubleCheck from "./img/ico-doublecheck.svg";
import icoEmpty from "./img/ico-empty.svg";
import icoWave from "./img/ico-wave.svg";
import fontMarianneMedium from "./../../component/fonts/Marianne-Medium.otf";
import fontMarianneBoldItalic from "./../../component/fonts/Marianne-BoldItalic.otf";
import diComprendreMessageOral from "./img/x-tao-competence-22eva6-comprendre_message_oral.svg";
import diComprendreTexte from "./img/x-tao-competence-22eva6-comprendre_texte.svg";
import diMesure from "./img/x-tao-competence-22eva6-grandeurs_unites_mesure.svg";
import diLexique from "./img/x-tao-competence-22eva6-lexique.svg";
import diLire from "./img/x-tao-competence-22eva6-lire_voix_haute.svg";
import diNombresCalculs from "./img/x-tao-competence-22eva6-nombres_calculs.svg";
import diGeometrie from "./img/x-tao-competence-22eva6-notions_geometrie.svg";
import diOrthographe from "./img/x-tao-competence-22eva6-orthographe.svg";
import diRepererPhase from "./img/x-tao-competence-22eva6-reperer_phrase.svg";
import diAutomatismes from "./img/x-tao-domain-22eva6-automatismes_nombres_grandeurs.svg";
import diProblemes from "./img/x-tao-domain-22eva6-problemes_nombres_grandeurs.svg";
import diHeadGears from "./img/x-tao-head-gears.svg";
import headGearsLine from "./img/x-tao-competence-23eva3-head-gears.svg";

import info from "./img/info.svg";


const map = {
    './img/ico-empty.svg': icoEmpty,
    './img/ico-wave.svg': icoWave,
    './img/ico-cross.svg': icoCross,
    './img/ico-doublecheck.svg': icoDoubleCheck,
    './img/ico-check.svg': icoCheck,
    './img/x-tao-competence-22eva6-comprendre_message_oral.svg': diComprendreMessageOral,
    './img/x-tao-competence-22eva6-comprendre_texte.svg': diComprendreTexte,
    './img/x-tao-competence-22eva6-grandeurs_unites_mesure.svg': diMesure,
    './img/x-tao-competence-22eva6-lexique.svg': diLexique,
    './img/x-tao-competence-22eva6-lire_voix_haute.svg': diLire,
    './img/x-tao-competence-22eva6-nombres_calculs.svg': diNombresCalculs,
    './img/x-tao-competence-22eva6-notions_geometrie.svg': diGeometrie,
    './img/x-tao-competence-22eva6-reperer_phrase.svg': diRepererPhase,
    './img/x-tao-domain-22eva6-automatismes_nombres_grandeurs.svg': diAutomatismes,
    './img/x-tao-domain-22eva6-problemes_nombres_grandeurs.svg': diProblemes,
    './img/x-tao-competence-22eva6-orthographe.svg': diOrthographe,
    './img/x-tao-head-gears.svg': diHeadGears,
    './img/x-tao-competence-23eva3-head-gears.svg': headGearsLine,
    './img/info.svg': info,
    './../../component/fonts/Marianne-BoldItalic.otf': convertBuffer(fontMarianneMedium),
    './../../component/fonts/Marianne-Medium.otf': convertBuffer(fontMarianneBoldItalic)
};

export default key => {
    if (map.hasOwnProperty(key)) {
        let result = map[key];

        if (result === '') {
            result = icoCheck;
        }

        return result;
    }

    console.log(`Missing resource ${key}`);

    return null;
}
