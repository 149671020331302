'use strict';

const labels = {
    //LINU
    "x-tao-domain-23linu-comprendre_un_support_ecrit": {
        "label": "Comprendre un\nsupport écrit",
        "parentLabel": "Aptitudes à comprendre\net à utiliser l'information\ndans la vie quotidienne"
    },
    "x-tao-domain-23linu-comprendre_un_document_audio": {
        "label": "Comprendre un\ndocument audio",
        "parentLabel": "Aptitudes à comprendre\net à utiliser l'information\ndans la vie quotidienne"
    },
    "x-tao-domain-23linu-lire_un_texte_a_voix_haute": {
        "label": "Lire un texte\nà voix haute",
        "parentLabel": "Fluence"
    },
    "x-tao-domain-23linu-comprendre_un_support_ecrit_deuxieme_partie": {
        "label": "Comprendre un\nsupport écrit",
        "parentLabel": "Test de compréhension\nde l'écrit de\nseconde professionnelle"
    },
    "x-tao-domain-23linu-exercice_complementaire_associer_un_groupe_de_lettres_a_un_son": {
        "label": "Associer un groupe\nde lettres à un son",
        "parentLabel": "Exercices complémentaires"
    },
    "x-tao-domain-23linu-dechiffrer_des_mots_rapidement": {
        "label": "Déchiffrer des mots\nrapidement",
        "parentLabel": "Exercices complémentaires"
    },
    "x-tao-domain-23linu-exercice_complementaire_connaitre_des_mots": {
        "label": "Connaître\ndes mots",
        "parentLabel": "Exercices complémentaires"
    },
    "x-tao-domain-23linu-exercice_complementaire_associer_une_image_a_une": {
        "label": "Associer une image\nà une phrase simple",
        "parentLabel": "Exercices complémentaires"
    },
    "x-tao-domain-23linu-resolution_de_problemes": {
        "label": "Résolution de problèmes",
        "parentLabel": "Capacité à utiliser\nles mathématiques\ndans le monde réel"
    },
    "x-tao-domain-23linu-automatismes": {
        "label": "Mobiliser directement\ndes procédures\net des connaissances",
        "parentLabel": "Test d’automatismes\nde seconde\nprofessionnelle"
    },
    "x-tao-domain-23linu-multiplications_et_comparaisons_de_nombres": {
        "label": "Multiplications et\ncomparaisons de\nnombres",
        "parentLabel": "Automatismes"
    },
    "x-tao-domain-23linu-pourcentages": {
        "label": "Pourcentages",
        "parentLabel": "Automatismes"
    },
    "x-tao-domain-23linu-soustractions": {
        "label": "Soustractions",
        "parentLabel": "Automatismes"
    },
};

export default labels;
