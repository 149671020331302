import menuVisibility from './menuVisibility';
import thresholds from './thresholds';
import $ from 'jquery';
import _ from 'lodash';

export default function systemSettings() {
    const settingSelector = '.system-setting';
    const collapsedClass = 'collapsed';
    const expandedClass = 'expanded';
    const spoilerSelector = '.system-setting__spoiler';

    function init() {
        $(document).on('click', spoilerSelector, onSpoilerClick);
        $(document).on('setting_saved', refreshPage);
        $(document).ready(() => {
            const url = new URL(location.href);
            const setting = url.searchParams.get('setting');

            if (setting !== null) {
                toggleSetting(setting);
            }
        });
    }

    function onSpoilerClick(e) {
        const settingId = $(e.currentTarget).closest(settingSelector).attr('id');

        toggleSetting(settingId);
    }

    function toggleSetting(settingId) {
        const $settingElement = $(`#${settingId}`);

        if ($settingElement.hasClass(collapsedClass)) {
            $settingElement.removeClass(collapsedClass).addClass(expandedClass);
        } else {
            $settingElement.removeClass(expandedClass).addClass(collapsedClass);
        }
    }

    function refreshPage(e, setting) {
        const url = new URL(location.href);
        url.searchParams.set('setting', setting);

        location.href = _.trimEnd(url.href, '#');
    }

    init();
    menuVisibility();
    thresholds();
}
