'use strict';

import _ from 'lodash';

import component from "@oat-sa/portal-core/src/core/component/component.js";
import feedbackChartTpl from './tpl/feedbackChartLinu.tpl';
import {feedbackChartApi} from "#chart/linu/feedbackChartLinu";
import "./scss/feedbackChartLinu.scss";

/**
 * Some default config values
 * @type {feedbackChartConfig}
 */
const defaultConfig = {
    paperWidth: 800,
    paperHeight: 500,
    paperMarginY: 0,
    marginX: 25,
    marginY: 50,
    xAxisHeight: 60,
    maxWidth: 0,
    maxHeight: 0,
    defaultFontSize: 12,
    defaultFontWeight: 'normal',
    titlePosition: 'bottom',
    markerFontSize: 10,
    xAxisFontSize: 12,
    yAxisFontSize: 13,
    yAxisLabelFontSize: 17,
    sideWidthPartOfPaperWidth: 0.5,
    pointsSidePartOfPaperWidth: 0.5,
    categoryWidthOfSideWidth: 0.55,

    fontFamily: 'Marianne',
    background: ['#fff', '#D0E5E8'],
    lineColor: '#17255f',
    textColor: '#17255f',
    primaryColor: '#57a5ad',
    lineThreshold: '#D3D3D3',

    rowHeight: 65,
    rowHeightMath: 85,
    showInfoBoxes: false,
    imageSize: 25,
    infoBlockFontSize: 10,
    infoBlockHeight: 60,
    infoBlockWidthPro: 320,
    infoBlockWidthComp: 240
};



/**
 * Default data set at init time
 * @type {feedbackChartData}
 */
const defaultData = {
    title: '',
    description: '',
    range: [0, 100],
    thresholds: [],
    categories: [],
    references: {},
    points: {},
    qr: {}
};

/**
 * Defines a component that displays a test results feedback to the test taker
 * @param {feedbackChartConfig} config - Some config properties
 * @param {Number} config.paperWidth - The overall width of the chart
 * @param {Number} config.paperHeight - The overall height of the chart
 * @param {Number} config.marginY - The vertical margin between elements in the chart
 * @param {Number} config.gradientHeight - The height of the gradient bar
 * @param {Number} config.xAxisHeight - The height of the X-axis labels area*
 * @param {Number} config.lineHeight - The height of a score line
 * @param {Number} config.maxWidth - The maximum width for the whole chart, the chart will be scaled if it oversizes it
 * @param {Number} config.maxHeight - The maximum height for the whole chart, the chart will be scaled if it oversizes it
 * @param {Number} config.defaultFontSize - Default font size
 * @param {Number} config.markerFontSize - Font size for the markers
 * @param {Number} config.xAxisFontSize - Font size for the X-axis
 * @param {Number} config.yAxisFontSize - Font size for the Y-axis
 * @param {String} config.fontFamily - The font face to use
 * @param {String} config.background - The color of the categories background
 * @param {String} config.lineColor - The color used for Y-axis lines
 * @param {String} config.textColor - The color of any text and X-axis lines
 * @param {String} config.sortCategories - The name of the field to sort categories by (default: none, no sort)
 * @param {feedbackChartData} [data] - Initial data set
 * @returns {feedbackChart}
 */
function feedbackChartFactory(config, data, qr = {}) {
    return component(feedbackChartApi, defaultConfig)
        .setTemplate(feedbackChartTpl)
        .on('init', function () {
            this.setData({ data, qr } || defaultData);
        })
        .on('render', function () {
            const $container = this.getElement();
            this.controls = {
                $graph: $container.find('.graph').empty()
            };

            this.repaint();
        })
        .on('destroy', function () {
            if (this.paper) {
                this.paper.remove();
            }

            _.forEach(properties, propName => {
                this[propName] = null;
            });
            this.controls = null;
            this.elements = null;
        })
        .init(config);
}

export default feedbackChartFactory
