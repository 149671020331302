'use strict';

import load from "./resourcesNode.js";
import path from 'path';
import {fileURLToPath} from 'url';
let __dirname = null;

if (typeof process === 'object') {
    const __filename = fileURLToPath(import.meta.url);
    __dirname = path.dirname(__filename);
}

const comprendreMessageOral = load("./img/x-tao-competence-22eva6-comprendre_message_oral.svg", __dirname);
const comprendreTexte = load("./img/x-tao-competence-22eva6-comprendre_texte.svg", __dirname);
const grandeursUnitesMesure = load("./img/x-tao-competence-22eva6-grandeurs_unites_mesure.svg", __dirname);
const lexique = load("./img/x-tao-competence-22eva6-lexique.svg", __dirname);
const lireVoixHaute = load("./img/x-tao-competence-22eva6-lire_voix_haute.svg", __dirname);
const nombresCalculs = load("./img/x-tao-competence-22eva6-nombres_calculs.svg", __dirname);
const notionsGeometrie = load("./img/x-tao-competence-22eva6-notions_geometrie.svg", __dirname);
const repererPhrase = load("./img/x-tao-competence-22eva6-reperer_phrase.svg", __dirname);
const automatismesNombresGrandeurs = load("./img/x-tao-domain-22eva6-automatismes_nombres_grandeurs.svg", __dirname);
const problemesNombresGrandeurs = load("./img/x-tao-domain-22eva6-problemes_nombres_grandeurs.svg", __dirname);
const orthographe = load("./img/x-tao-competence-22eva6-orthographe.svg", __dirname);
const headGears = load("./img/x-tao-head-gears.svg", __dirname);
const headGearsLine = load("./img/x-tao-competence-23eva3-head-gears.svg", __dirname);

const images = {
    // EVA2GT
    'x-tao-competence-23eva2gt-comprendre_message_oral': comprendreMessageOral,
    'x-tao-competence-23eva2gt-comprendre_texte': comprendreTexte,
    'x-tao-competence-23eva2gt-grandeurs_unites_mesure': grandeursUnitesMesure,
    'x-tao-competence-23eva2gt-lexique': lexique,
    'x-tao-competence-23eva2gt-lire_voix_haute': lireVoixHaute,
    'x-tao-competence-23eva2gt-utiliser_dans_des_calculs': nombresCalculs,
    'x-tao-competence-23eva2gt-notions_de_geometrie': notionsGeometrie,
    'x-tao-competence-23eva2gt-grammaire': repererPhrase,
    'x-tao-competence-23eva2gt-procedures_et_connaissances': automatismesNombresGrandeurs,
    'x-tao-competence-23eva2gt-resoudre_des_problemes': problemesNombresGrandeurs,
    'x-tao-competence-23eva2gt-orthographe': orthographe,
    'x-tao-competence-23eva2gt-notion_de_fonction': headGears,

    // EVA2PRO
    'x-tao-competence-23eva2pro-comprendre_message_oral': comprendreMessageOral,
    'x-tao-competence-23eva2pro-comprendre_texte': comprendreTexte,
    'x-tao-competence-23eva2pro-grandeurs_unites_mesure': grandeursUnitesMesure,
    'x-tao-competence-23eva2pro-lexique': lexique,
    'x-tao-competence-23eva2pro-lire_voix_haute': lireVoixHaute,
    'x-tao-competence-23eva2pro-utiliser_dans_des_calculs': nombresCalculs,
    'x-tao-competence-23eva2pro-notions_de_geometrie': notionsGeometrie,
    'x-tao-competence-23eva2pro-grammaire': repererPhrase,
    'x-tao-competence-23eva2pro-procedures_et_connaissances': automatismesNombresGrandeurs,
    'x-tao-competence-23eva2pro-resoudre_des_problemes': problemesNombresGrandeurs,
    'x-tao-competence-23eva2pro-orthographe': orthographe,
    'x-tao-competence-23eva2pro-notion_de_fonction': headGears,

    // EVA4
    'x-tao-competence-23eva4-comprendre_un_message_oral': comprendreMessageOral,
    'x-tao-competence-23eva4-comprendre_un_texte': comprendreTexte,
    'x-tao-competence-23eva4-unites_de_mesure': grandeursUnitesMesure,
    'x-tao-competence-23eva4-mobiliser_le_lexique': lexique,
    'x-tao-competence-23eva4-lire_voix_haute': lireVoixHaute,
    'x-tao-competence-23eva4-nombres_dans_des_calculs': nombresCalculs,
    'x-tao-competence-23eva4-notions_de_geometrie': notionsGeometrie,
    'x-tao-competence-23eva4-identifier_sa_composition': repererPhrase,
    'x-tao-competence-23eva4-procedures_et_connaissances': automatismesNombresGrandeurs,
    'x-tao-competence-23eva4-utilisant_des_nombres': problemesNombresGrandeurs,
    'x-tao-competence-23eva4-maitriser_orthographe': orthographe,
    'x-tao-competence-23eva4-notion_de_fonction': headGears,

    // EVA6
    'x-tao-competence-23eva6-comprendre_message_oral': comprendreMessageOral,
    'x-tao-competence-23eva6-comprendre_texte': comprendreTexte,
    'x-tao-competence-23eva6-grandeurs_unites_mesure': grandeursUnitesMesure,
    'x-tao-competence-23eva6-lexique': lexique,
    'x-tao-competence-23eva6-lire_voix_haute': lireVoixHaute,
    'x-tao-competence-23eva6-nombres_calculs': nombresCalculs,
    'x-tao-competence-23eva6-notions_geometrie': notionsGeometrie,
    'x-tao-competence-23eva6-reperer_phrase': repererPhrase,
    'x-tao-competence-23eva6-automatismes_nombres_grandeurs': automatismesNombresGrandeurs,
    'x-tao-competence-23eva6-problemes_nombres_grandeurs': problemesNombresGrandeurs,
    'x-tao-competence-23eva6-orthographe': orthographe,

    // EVA3
    'x-tao-domain-24eva3-fluence': lireVoixHaute,
    'x-tao-domain-24eva3-automatismes': automatismesNombresGrandeurs,
    'x-tao-domain-24eva3-competences-langagieres-et-linguistiques': headGearsLine,

    // EVA5
    'x-tao-domain-24eva5-fluence': lireVoixHaute,
    'x-tao-domain-24eva5-automatismes': automatismesNombresGrandeurs,
    'x-tao-domain-24eva5-competences-langagieres-et-linguistiques': headGearsLine,
}

export default images;
