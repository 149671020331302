import $ from 'jquery';
import Handlebars from 'handlebars';

export default function feedbackSelector() {
    const rootSelector = '.feedback-selector';
    const campaignSelector = '.feedback-selector__campaign';
    const subjectSelector = '.feedback-selector__subject';
    const gradeSelector = '.feedback-selector__grade';
    const optionTpl = Handlebars.compile('<option value="{{value}}">{{label}}</option>');
    let selectorsStructure = {};

    function init() {
        selectorsStructure = $(rootSelector).data('structure');

        $(document).on('change', campaignSelector, onCampaignChange);
        $(document).on('change', subjectSelector, onSubjectChange);
        $(document).on('change', gradeSelector, onGradeChange);

        updateCampaign();
    }

    function onCampaignChange() {
        updateRelatedInput($(campaignSelector));
        updateSubject($(campaignSelector).val());
    }

    function onSubjectChange() {
        updateRelatedInput($(subjectSelector));
        updateGrade($(campaignSelector).val(), $(subjectSelector).val());
    }

    function onGradeChange() {
        updateRelatedInput($(gradeSelector));
    }

    function updateCampaign() {
        clearSelect(campaignSelector);
        Object.keys(selectorsStructure).forEach(key => {
            $(optionTpl({ value: key, label: key })).appendTo(campaignSelector);
        });
        let campaign = $(campaignSelector).val();
        updateRelatedInput($(campaignSelector));
        updateSubject(campaign);
    }

    function updateSubject(campaign) {
        clearSelect(subjectSelector);
        Object.keys(selectorsStructure[campaign]).forEach(key => {
            $(optionTpl({ value: key, label: key })).appendTo(subjectSelector);
        });
        updateRelatedInput($(subjectSelector));
        updateGrade($(campaignSelector).val(), $(subjectSelector).val());
    }

    function updateGrade(campaign, subject) {
        clearSelect(gradeSelector);
        Object.keys(selectorsStructure[campaign][subject]).forEach(key => {
            $(optionTpl({ value: key, label: key })).appendTo(gradeSelector);
        });
        updateRelatedInput($(gradeSelector));
    }

    function clearSelect(selector) {
        $(selector)
            .find('option')
            .each((idx, item) => $(item).remove());
    }

    function updateRelatedInput(relatedSelectElement) {
        const name = $(relatedSelectElement).attr('name');
        const relatedInputElement = $(relatedSelectElement).siblings(`input[name="${name}"]`);
        const value = $(relatedSelectElement).val();

        $(relatedInputElement).val(value);
    }

    init();
}
