var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return "<strong>"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + "</strong><br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div id=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"alert alert-"
    + alias5(((helper = (helper = helpers.level || (depth0 != null ? depth0.level : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"level","hash":{},"data":data}) : helper)))
    + " border-"
    + alias5(((helper = (helper = helpers.level || (depth0 != null ? depth0.level : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"level","hash":{},"data":data}) : helper)))
    + "\" role=\"alert\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Fermer\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n    <svg class=\"icon\">\n        <use xlink:href=\"/images/icons.svg#icon-"
    + alias5(((helper = (helper = helpers.level || (depth0 != null ? depth0.level : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"level","hash":{},"data":data}) : helper)))
    + "\"></use>\n    </svg>\n    <div>\n        "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});