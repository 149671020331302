import $ from 'jquery';

export default function menuVisibility() {
    const settingId = 'menu_visibility';
    const $form = $('#menu-visibility-form');
    const $inputs = $form.find('.menu-visibility-checkbox');

    function init() {
        $inputs.each((idx, item) => {
            $(item).on('change', onChange);
        });
    }

    function onChange() {
        post().always(() => $(document).trigger('setting_saved', settingId));
    }

    function post() {
        return $.post($form.attr('action'), getData());
    }

    function getData() {
        let data = {
            setting: $form.data('setting'),
            value: {}
        };

        $inputs.each((idx, item) => {
            data.value[$(item).attr('name')] = $(item).is(':checked') ? 1 : 0;
        });

        return data;
    }

    init();
}
