'use strict';

import _ from "lodash";

import component from "@oat-sa/portal-core/src/core/component/component.js";
import feedbackChartTpl from './tpl/feedbackChartEva6.tpl';
import {feedbackChartApi} from "#chart/eva/feedbackChartEva";
import "./scss/feedbackChartEva6.scss"

const defaultConfig = {
    paperWidth: 900,
    paperHeight: 800,
    marginX: 40,
    marginY: 40,
    xAxisHeight: 20,
    maxWidth: 0,
    maxHeight: 500,
    defaultFontSize: 12,
    defaultFontWeight: 'normal',
    titlePosition: 'bottom',
    markerFontSize: 10,
    xAxisFontSize: 13,
    yAxisFontSize: 18,
    yAxisLabelFontSize: 13,
    sideWidthPartOfPaperWidth: 0.4,
    categoryWidthOfSideWidth: 0.6,

    fontFamily: 'Marianne',
    fontFamilyExtraBold: 'Marianne ExtraBold',
    fontFamilyMediumItalic : 'Marianne MediumItalic',
    background: ['#fff', '#30ae7c'],
    lineColor: '#17255f',
    textColor: '#17255f',
    primaryColor: '#30ae7c',

    lineHeight: 85,
    pointRadius: 20,
    imageWidth: 25,
    paddingRight: 60,
    paddingLeft: 30,

    grade: null,
    showTopLabels: true
};

/**
 * Default data set at init time
 * @type {feedbackChartData}
 */
const defaultData = {
    title: '',
    description: '',
    range: [0, 100],
    thresholds: [],
    categories: [],
    references: {},
    points: {},
    qr: {}
};

/**
 * Defines a component that displays a test results feedback to the test taker
 * @param {feedbackChartConfig} config - Some config properties
 * @param {Number} config.paperWidth - The overall width of the chart
 * @param {Number} config.paperHeight - The overall height of the chart
 * @param {Number} config.marginY - The vertical margin between elements in the chart
 * @param {Number} config.gradientHeight - The height of the gradient bar
 * @param {Number} config.xAxisHeight - The height of the X-axis labels area*
 * @param {Number} config.lineHeight - The height of a score line
 * @param {Number} config.maxWidth - The maximum width for the whole chart, the chart will be scaled if it oversizes it
 * @param {Number} config.maxHeight - The maximum height for the whole chart, the chart will be scaled if it oversizes it
 * @param {Number} config.defaultFontSize - Default font size
 * @param {Number} config.markerFontSize - Font size for the markers
 * @param {Number} config.xAxisFontSize - Font size for the X-axis
 * @param {Number} config.yAxisFontSize - Font size for the Y-axis
 * @param {String} config.fontFamily - The font face to use
 * @param {String} config.background - The color of the categories background
 * @param {String} config.lineColor - The color used for Y-axis lines
 * @param {String} config.textColor - The color of any text and X-axis lines
 * @param {String} config.sortCategories - The name of the field to sort categories by (default: none, no sort)
 * @param {feedbackChartData} [data] - Initial data set
 * @returns {feedbackChart}
 */
function feedbackChartFactory(config, data, qr = {}) {
    return component(feedbackChartApi, defaultConfig)
        .setTemplate(feedbackChartTpl)
        .on('init', function () {
            this.setData({ data, qr } || defaultData);
        })
        .on('render', function () {
            const $container = this.getElement();
            this.controls = {
                $graph: $container.find('.graph').empty()
            };

            this.repaint();
        })
        .on('destroy', function () {
            if (this.paper) {
                this.paper.remove();
            }

            _.forEach(properties, propName => {
                this[propName] = null;
            });
            this.controls = null;
            this.elements = null;
        })
        .init(config, qr);
}

export default feedbackChartFactory;
