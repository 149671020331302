import $ from 'jquery';
import _ from 'lodash';
import FeedbackSelector from './feedbackSelector';
import i18n from '../../i18n/i18n';

export default function thresholds() {
    const settingId = 'thresholds';
    const formSelector = '#thresholds-form';
    const thresholdSelector = '.threshold__body';
    const editButtonSelector = '.threshold-edit-button';
    const saveButtonSelector = '.threshold-save-button';
    const deleteButtonSelector = '.threshold-delete-button';
    const cancelButtonSelector = '.threshold-cancel-button';
    const addButtonSelector = '.threshold-add-button';
    const saveNewButtonSelector = '.threshold-save-new-button';
    const cancelNewSelector = '.threshold-cancel-new-button';
    const addBlockSelector = '.threshold-add-block';
    const editModeClass = 'edit';
    const viewModeClass = 'view';

    const requiredFields = ['label', 'value', 'color'];

    let thresholdState = {
        uniqueId: null,
        data: {}
    };

    function init() {
        $(document).on('click', editButtonSelector, editThreshold);
        $(document).on('click', saveButtonSelector, updateThreshold);
        $(document).on('click', deleteButtonSelector, deleteThreshold);
        $(document).on('click', cancelButtonSelector, cancelEdit);
        $(document).on('click', addButtonSelector, addThreshold);
        $(document).on('click', saveNewButtonSelector, saveNewThreshold);
        $(document).on('click', cancelNewSelector, cancelNew);

        FeedbackSelector();
    }

    function editThreshold(e) {
        if (thresholdState.uniqueId !== null) {
            restoreThresholdState();
        }

        $(formSelector)
            .find(thresholdSelector)
            .each((idx, item) => {
                setViewMode(item);
            });

        let threshold = $(e.target).closest(thresholdSelector);

        rememberThresholdState($(threshold).data('threshold-unique-id'));

        setEditMode(threshold);
    }

    function updateThreshold(e) {
        const $element = $(e.currentTarget).closest(thresholdSelector);
        const thresholdData = getThresholdData($element);
        if (validate(thresholdData)) {
            save();
        }
    }

    function save() {
        post().always(() => {
            $(document).trigger('setting_saved', settingId);
        });
    }

    function cancelEdit(e) {
        restoreThresholdState();
        const $threshold = $(e.target).closest(thresholdSelector);
        setViewMode($threshold);
    }

    function deleteThreshold(e) {
        if (!window.confirm(i18n.t('threshold_delete_confirm'))) {
            return;
        }
        const $threshold = $(e.target).closest(thresholdSelector);

        $threshold.remove();

        save();
    }

    function addThreshold() {
        $(addBlockSelector).removeClass('d-none');
    }

    function saveNewThreshold(e) {
        $(e.currentTarget).attr('disabled', true);

        const thresholdData = getThresholdData($(addBlockSelector));

        if (validate(thresholdData)) {
            $(addBlockSelector)
                .clone()
                .addClass('d-none ' + thresholdSelector.slice(1))
                .appendTo(formSelector);
            save();
        } else {
            $(e.currentTarget).removeAttr('disabled');
        }
    }

    function cancelNew() {
        $(addBlockSelector).addClass('d-none');
    }

    function rememberThresholdState(uniqueId) {
        const $threshold = $(formSelector).find('.threshold__body[data-threshold-unique-id="' + uniqueId + '"]');

        thresholdState['uniqueId'] = uniqueId;
        thresholdState['data'] = getThresholdData($threshold);
    }

    function restoreThresholdState() {
        if (thresholdState.uniqueId === null) {
            console.error('Cannot restore the threshold state. State not saved.');

            return;
        }

        const $threshold = $(formSelector).find(
            `.threshold__body[data-threshold-unique-id="${thresholdState.uniqueId}"]`
        );

        if ($threshold.length === 0) {
            console.error('Cannot find a threshold with provided uniqueId');

            return;
        }

        $threshold.find('input[name="label"]').val(thresholdState.data.label);
        $threshold.find('input[name="group"]').val(thresholdState.data.group);
        $threshold.find('input[name="value"]').val(thresholdState.data.value);
        $threshold.find('input[name="color"]').val(thresholdState.data.color);
    }

    function setEditMode(element) {
        $(element).removeClass(viewModeClass);
        $(element).addClass(editModeClass);
    }

    function setViewMode(element) {
        $(element).removeClass(editModeClass);
        $(element).addClass(viewModeClass);
    }

    function post() {
        return $.post($(formSelector).attr('action'), getData());
    }

    function getData() {
        let data = {
            setting: $(formSelector).data('setting'),
            value: {}
        };
        let payloadData = {};

        $(formSelector)
            .find(thresholdSelector)
            .each((idx, item) => {
                let campaign = $(item).find('input[name="campaign"]').val();
                let subject = $(item).find('input[name="subject"]').val();
                let gradeInput = $(item).find('input[name="grade"]');
                let grade = gradeInput.length ? $(gradeInput).val() : null;

                let thresholdData = getThresholdData(item);

                if (typeof payloadData[campaign] === 'undefined') {
                    payloadData[campaign] = {};
                }

                if (typeof payloadData[campaign][subject] === 'undefined') {
                    payloadData[campaign][subject] = _.isEmpty(grade) ? [] : {};
                }

                const sortFn = (left, right) => left.value - right.value;

                if (_.isEmpty(grade)) {
                    payloadData[campaign][subject].push(thresholdData);
                    payloadData[campaign][subject].sort(sortFn);

                    return;
                }

                if (typeof payloadData[campaign][subject][grade] === 'undefined') {
                    payloadData[campaign][subject][grade] = [];
                }

                payloadData[campaign][subject][grade].push(thresholdData);
                payloadData[campaign][subject][grade].sort(sortFn);
            });

        data['value'] = payloadData;

        return data;
    }

    function getThresholdData(element) {
        let label = $(element).find('input[name="label"]').val();
        let group = $(element).find('input[name="group"]').val();
        let value = $(element).find('input[name="value"]').val();
        let color = $(element).find('input[name="color"]').val().slice(1, 7);

        return {
            label: label,
            group: group,
            value: Number(value),
            color: color
        };
    }

    function validate(thresholdData) {
        let isValid = true;

        _.forEach(requiredFields, field => {
            if (
                typeof thresholdData[field] === 'undefined' ||
                String(thresholdData[field]).trim() === '' ||
                thresholdData[field] === 0
            ) {
                isValid = false;
                alert(i18n.t('field_cant_be_empty', {field: _.upperFirst(field)}));

                return false;
            }
        });

        return isValid;
    }

    init();
}
