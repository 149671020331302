'use strict';

const labels = {
    //EVA2 - GT
    "x-tao-competence-23eva2gt-comprendre_message_oral": {
        "label": "Comprendre un\nmessage oral",
        "parentLabel": "Compréhension\nde l'oral"
    },
    "x-tao-competence-23eva2gt-comprendre_texte": {
        "label": "Comprendre un texte",
        "parentLabel": "Compréhension\nde l'écrit"
    },
    "x-tao-competence-23eva2gt-grammaire": {
        "label": "Mobiliser ses connaissances\nen grammaire pour\nanalyser des phrases",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2gt-orthographe": {
        "label": "Maîtriser l'orthographe",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2gt-lexique": {
        "label": "Comprendre et\nmobiliser le lexique",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2gt-procedures_et_connaissances": {
        "label": "Mobiliser directement\ndes procédures et\ndes connaissances",
        "parentLabel": "Automatismes"
    },
    "x-tao-competence-23eva2gt-notions_de_geometrie": {
        "label": "Connaître et utiliser\ndes notions de géométrie",
        "parentLabel": "Espace\net géométrie"
    },
    "x-tao-competence-23eva2gt-resoudre_des_problemes": {
        "label": "Utiliser des expressions\nlittérales pour traduire\nou résoudre des problèmes",
        "parentLabel": "Expressions\nalgébriques"
    },
    "x-tao-competence-23eva2gt-utiliser_dans_des_calculs": {
        "label": "Connaître les nombres et\nles utiliser dans des calculs",
        "parentLabel": "Nombres et\ncalculs"
    },
    "x-tao-competence-23eva2gt-notion_de_fonction": {
        "label": "Connaître et utiliser des\ndonnées et la notion\nde fonction",
        "parentLabel": "Organisation\net gestion de\ndonnées, fonctions"
    },
    //EVA2 - PRO
    "x-tao-competence-23eva2pro-comprendre_message_oral": {
        "label": "Comprendre un\nmessage oral",
        "parentLabel": "Compréhension\nde l'oral"
    },
    "x-tao-competence-23eva2pro-comprendre_texte": {
        "label": "Comprendre un texte",
        "parentLabel": "Compréhension\nde l'écrit"
    },
    "x-tao-competence-23eva2pro-grammaire": {
        "label": "Mobiliser ses connaissances\nen grammaire pour\nanalyser des phrases",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2pro-orthographe": {
        "label": "Maîtriser l'orthographe",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2pro-lexique": {
        "label": "Comprendre et\nmobiliser le lexique",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva2pro-procedures_et_connaissances": {
        "label": "Mobiliser directement\ndes procédures et\ndes connaissances",
        "parentLabel": "Automatismes"
    },
    "x-tao-competence-23eva2pro-notions_de_geometrie": {
        "label": "Connaître et utiliser\ndes notions de géométrie",
        "parentLabel": "Espace\net géométrie"
    },
    "x-tao-competence-23eva2pro-utiliser_dans_des_calculs": {
        "label": "Connaître les nombres et\nles utiliser dans des calculs\nUtiliser le calcul littéral",
        "parentLabel": "Nombres et\ncalculs"
    },
    "x-tao-competence-23eva2pro-notion_de_fonction": {
        "label": "Connaître et utiliser des\ndonnées et la notion\nde fonction",
        "parentLabel": "Organisation\net gestion de\ndonnées, fonctions"
    },
    //EVA4
    "x-tao-competence-23eva4-comprendre_un_message_oral": {
        "label": "Comprendre un\nmessage oral",
        "parentLabel": "Compréhension\nde l'oral"
    },
    "x-tao-competence-23eva4-comprendre_un_texte": {
        "label": "Comprendre un texte",
        "parentLabel": "Compréhension\nde l'écrit"
    },
    "x-tao-competence-23eva4-identifier_sa_composition": {
        "label": "Se repérer dans une phrase\net identifier sa composition",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva4-maitriser_orthographe": {
        "label": "Maîtriser l'orthographe",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva4-mobiliser_le_lexique": {
        "label": "Comprendre et\nmobiliser le lexique",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva4-lire_voix_haute": {
        "label": "Lire un texte à voix haute",
        "parentLabel": "Fluence"
    },
    "x-tao-competence-23eva4-procedures_et_connaissances": {
        "label": "Mobiliser directement\ndes procédures et\ndes connaissances",
        "parentLabel": "Automatismes"
    },
    "x-tao-competence-23eva4-utilisant_des_nombres": {
        "label": "Résoudre des problèmes\nen utilisant des nombres,\ndes données et des grandeurs",
        "parentLabel": "Résolution\nde problèmes"
    },
    "x-tao-competence-23eva4-notions_de_geometrie": {
        "label": "Connaître et utiliser des\nnotions de géométrie",
        "parentLabel": "Espace\net géométrie"
    },
    "x-tao-competence-23eva4-unites_de_mesure": {
        "label": "Connaître des grandeurs et\nutiliser des unités de mesure",
        "parentLabel": "Grandeurs\net mesures"
    },
    "x-tao-competence-23eva4-nombres_dans_des_calculs": {
        "label": "Connaître les nombres et les\nutiliser dans des calculs",
        "parentLabel": "Nombres\net calculs"
    },
    "x-tao-competence-23eva4-notion_de_fonction": {
        "label": "Connaître et utiliser des\ndonnées et la notion\nde fonction",
        "parentLabel": "Organisation\net gestion de\ndonnées, fonctions"
    },
    //EVA6
    "x-tao-competence-23eva6-comprendre_message_oral": {
        "label": "Comprendre un\nmessage oral",
        "parentLabel": "Compréhension\nde l'oral"
    },
    "x-tao-competence-23eva6-comprendre_texte": {
        "label": "Comprendre un texte",
        "parentLabel": "Compréhension\nde l'écrit"
    },
    "x-tao-competence-23eva6-reperer_phrase": {
        "label": "Se repérer dans une phrase\net identifier sa composition",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva6-orthographe": {
        "label": "Maîtriser l'orthographe",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva6-lexique": {
        "label": "Comprendre et\nmobiliser le lexique",
        "parentLabel": "Étude de la\nlangue"
    },
    "x-tao-competence-23eva6-lire_voix_haute": {
        "label": "Lire un texte à voix haute",
        "parentLabel": "Fluence"
    },
    "x-tao-competence-23eva6-automatismes_nombres_grandeurs": {
        "label": "Mobiliser directement\ndes procédures et\ndes connaissances",
        "parentLabel": "Automatismes"
    },
    "x-tao-competence-23eva6-problemes_nombres_grandeurs": {
        "label": "Résoudre des problèmes\nen utilisant des nombres\net des grandeurs",
        "parentLabel": "Résolution de\nproblèmes"
    },
    "x-tao-competence-23eva6-notions_geometrie": {
        "label": "Connaître et utiliser des\nnotions de géométrie",
        "parentLabel": "Espace\net géométrie"
    },
    "x-tao-competence-23eva6-grandeurs_unites_mesure": {
        "label": "Connaître des grandeurs et\nutiliser des unités de mesure",
        "parentLabel": "Grandeurs\net mesures"
    },
    "x-tao-competence-23eva6-nombres_calculs": {
        "label": "Connaître les nombres et les\nutiliser dans des calculs",
        "parentLabel": "Nombres\net calculs"
    },
    "x-tao-domain-24eva3-competences-langagieres-et-linguistiques": {
        label: "Mobiliser des compétences\n" +
            "en langue liées à la\n" +
            "compréhension ou à la\n" +
            "production écrites et orales",
        parentLabel: "Compétences\nlangagières et\nlinguistiques"
    },
    "x-tao-domain-24eva3-automatismes": {
        label: "Mobiliser directement\ndes procédures et\ndes connaissances",
        parentLabel: "Automatismes"
    },
    "x-tao-domain-24eva5-competences-langagieres-et-linguistiques": {
        label: "Mobiliser des compétences\n" +
            "en langue liées à la\n" +
            "compréhension ou à la\n" +
            "production écrites et orales",
        parentLabel: "Compétences\nlangagières et\nlinguistiques"
    },
    "x-tao-domain-24eva5-automatismes": {
        label: "Mobiliser directement\ndes procédures et\ndes connaissances",
        parentLabel: "Automatismes"
    },
    "x-tao-domain-24eva5-fluence": {
        label: "Lire un texte à voix haute",
        parentLabel: "Fluence"
    }
};

export default labels;
