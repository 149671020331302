'use strict';

import convertBuffer from "#libs/encodeFontBuffer";

import fontMarianneMedium from "./../../component/fonts/Marianne-Medium.otf";

import icoWave from "./img/ico-wave.svg";
import icoCross from "./img/ico-cross.svg";
import icoCheck from "./img/ico-check.svg";
import icoInfo from "./img/ico-info.svg";
import categoryDollText from "./img/categories/doll-text.svg";
import categoryHeadMath from "./img/categories/head-math-operations.svg";
import categoryHeadSound from "./img/categories/head-sound.svg";
import categoryHeadText from "./img/categories/head-text.svg";
import categoryLetterSound from "./img/categories/letter-sound.svg";
import categoryLetterBaloon from "./img/categories/letter-baloon.svg";
import categoryPuzzleQuestion from "./img/categories/puzzle-question.svg";
import categoryOperationsMinus from "./img/categories/operations-minus.svg";
import categoryOperationsBigger from "./img/categories/operations-bigger.svg";
import categoryOperationsPercentage from "./img/categories/operations-percent.svg";

const map = {
    './component/fonts/Marianne-Medium.otf': convertBuffer(fontMarianneMedium),
    "./img/ico-wave.svg": icoWave,
    "./img/ico-cross.svg": icoCross,
    "./img/ico-check.svg": icoCheck,
    "./img/ico-info.svg": icoInfo,
    "./img/categories/doll-text.svg": categoryDollText,
    "./img/categories/head-math-operations.svg": categoryHeadMath,
    "./img/categories/head-sound.svg": categoryHeadSound,
    "./img/categories/head-text.svg": categoryHeadText,
    "./img/categories/letter-sound.svg": categoryLetterSound,
    "./img/categories/letter-baloon.svg": categoryLetterBaloon,
    "./img/categories/puzzle-question.svg": categoryPuzzleQuestion,
    "./img/categories/operations-minus.svg": categoryOperationsMinus,
    "./img/categories/operations-bigger.svg": categoryOperationsBigger,
    "./img/categories/operations-percent.svg": categoryOperationsPercentage,
};

export default key => {
    if (map.hasOwnProperty(key)) {
        return map[key];
    }

    console.log(`Missing resource ${key}`);

    return null;
}
